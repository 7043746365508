import { createContext, useReducer } from 'react'

export const CalendarContext = createContext()

export const calendarsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CALENDAR':
            return {
                calendar: action.payload
            }
        case 'CREATE_EVENT':
            return {
                calendar: [action.payload, ...state.calendar]
            }
        case 'DELETE_EVENT':
            return {
                calendar: state.calendar.filter((w) => w._id !== action.payload._id)
            }
        case 'UPDATE_EVENT':
            return {
                calendar: action.payload
            }
        default:
            return state
    }
}

export const CalendarContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(calendarsReducer, {
        calendar: null
    })

    return (
        <CalendarContext.Provider value={{ ...state, dispatch }}>
            {children}
        </CalendarContext.Provider>
    )
}