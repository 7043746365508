import $ from 'jquery';
import './login.css';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useLogin } from '../hooks/useLogin'

const Login = () => {
    const [orgUserEmail, setEmail] = useState('');
    const [orgUserPassword, setPassword] = useState('');
    const { login, error, isLoading } = useLogin();

    $(function () {
        $("a").removeClass("current");
    });
    const handleSubmit = async (e) => {
        e.preventDefault()
        await login(orgUserEmail, orgUserPassword)
        console.log(error)
    }

    return (
        <div className='page'>
            <h1 className='login-header'>Welcome to the 1900 N. Akard Tenant Portal</h1>
            <div className="page-body">
                <form className="login-form" onSubmit={handleSubmit}>
                    <img src={require('../assets/Akard_Logo_Black.png')} alt='' />
                    <div className='login-block'>
                        <label>Email</label>
                        <input
                            type='email'
                            onChange={(e) => setEmail(e.target.value)}
                            value={orgUserEmail}
                        />
                    </div>
                    <div className='login-block'>
                        <label>Password</label>
                        <input
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}
                            value={orgUserPassword}
                        />
                    </div>
                    <Link to='/forgot-password' className='forgotten'> Forgot Password? </Link>
                    <Link className='mobile-only forgotten' to='/admin-login'>Admin Login</Link>
                    <Link to='/signup' className='forgotten hide'> Create Login</Link>
                    <button>Log In</button>
                    {error && <div className="error">{error}</div>}
                </form>
            </div>
        </div>
    )
}

export default Login