import { OrgContext } from '../context/OrgContext'
import { useContext } from 'react'

export const useOrgContext = () => {
    const context = useContext(OrgContext)

    if (!context) {
        throw Error('useOrgContext must be used inside an OrgContextProvider')
    }

    return context
}