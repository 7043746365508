import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()

    const signup = async (orgUserName,
        orgUserFName,
        orgUserLName,
        orgUserPassword,
        orgName,
        orgUserEmail) => {
        setIsLoading(true)
        setError(null)

        const response = await fetch('https://akard-dashboard-api.onrender.com/api/orgUsers/signup', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                orgUserName,
                orgUserFName,
                orgUserLName,
                orgUserPassword,
                orgName,
                orgUserEmail
            })
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }
        if (response.ok) {
            // save the user to local storage
            // localStorage.setItem('user', JSON.stringify(json))

            // update the auth context
            // dispatch({ type: 'LOGIN', payload: json })

            // update loading state
            setIsLoading(false)
        }
    };
    const adminsignup = async (
        adminUserFName,
        adminUserLName,
        adminUserPassword,
        adminUserEmail) => {
        setIsLoading(true)
        setError(null)

        const response = await fetch('https://akard-dashboard-api.onrender.com/api/adminUsers/signupadmin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                adminUserFName,
                adminUserLName,
                adminUserPassword,
                adminUserEmail
            })
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }
        if (response.ok) {
            // save the user to local storage
            // localStorage.setItem('user', JSON.stringify(json))

            // update the auth context
            // dispatch({ type: 'LOGIN', payload: json })

            // update loading state
            setIsLoading(false)
        }
    }

    return { signup, isLoading, error, adminsignup }
}