import $ from 'jquery';

const Visitor = () => {
    $(function () {
        $("a").removeClass("current");
        $('a[href="' + document.location.pathname + '"]').addClass("current");
    });
    return (
        <div className='page'>
            <div className='page-header'>
                <img src={require('../assets/visitor-icon-black.png')} alt='' />
                <h1>Visitor Management</h1>
            </div>
            <div className="page-body">
                <p>*If the SPLAN login does not appear below, please make sure you have cookie blockers disabled as this login requires the use of cookies.</p>
                <iframe src="https://visitors.splan.com/splan-ui/#login" title='SPLAN Login'></iframe>
            </div>
        </div>
    )
}

export default Visitor