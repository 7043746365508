import $ from 'jquery';
import { Link } from "react-router-dom";

import MobileDashboard from '../components/MobileDashboard';

const Dashboard = () => {
    $(function () {
        $("a").removeClass("current");
    });

    return (
        <div className='page'>
            <h1 className='dashboard-header'>1900 North Akard Tenant Portal</h1>
            <div className="page-body user-grid">
                {/* <h2>Building News</h2>
                <img className='construction-logo' src={require('../assets/1900Construction_v2.jpg')} alt='' /> */}
                <Link className='user-dashboard-button' to='/visitor'>
                    <img className="user-icon" src={require('../assets/visitor-icon-black.png')} alt='' />
                    <p className="link-label">Visitors</p>
                </Link>
                {/* <Link className='user-dashboard-button' to='/conference-room'>
                    <img className="user-icon" src={require('../assets/conference-icon-black.png')} alt='' />
                    <p className="link-label">Conference Room</p>
                </Link> */}
                <Link className='user-dashboard-button' to='/parking'>
                    <img className="user-icon" src={require('../assets/parking-icon-black.png')} alt='' />
                    <p className="link-label">Parking</p>
                </Link>
                <Link className='user-dashboard-button' to='/maintenance'>
                    <img className="user-icon" src={require('../assets/maintenance-icon-black.png')} alt='' />
                    <p className="link-label">Maintenance</p>
                </Link>
            </div>
            <MobileDashboard className='mobile-dashboard' />

        </div>
    )
}

export default Dashboard