import React, { useEffect } from "react";
import $ from 'jquery';

// hooks
import { useOrgContext } from "../hooks/useOrgContext.js";
import { useAuthContext } from "../hooks/useAuthContext";

// components
import OrgDetails from '../components/OrgDetails'
import OrgForm from '../components/OrgForm'

const Organizations = () => {
    $(function () {
        $("a").removeClass("current");
    });
    const { orgs, dispatch } = useOrgContext();
    const { admin } = useAuthContext();

    useEffect(() => {
        const fetchOrgs = async () => {
            const response = await fetch('https://akard-dashboard-api.onrender.com/api/organizations', {
                headers: { 'Authorization': `Bearer ${admin.token}` },
            })
            const json = await response.json()

            if (response.ok) {
                dispatch({ type: 'SET_ORGS', payload: json })
            }
        }

        if (admin) {
            fetchOrgs()
            // console.log(orgs)
        }
    }, [dispatch, admin])

    return (
        <div className='page'>
            <h1 className='dashboard-header'>Registered Organizations</h1>
            <div className="page-body can-scroll">
                {/* <h2>Registered Organizations</h2> */}
                <OrgForm />
                <div className="org-wrapper">
                    {orgs && orgs.map((org) => (
                        // console.log(org)
                        <OrgDetails key={org._id} orgs={org} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Organizations;
