import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
    const { dispatch } = useAuthContext()

    const logout = () => {
        // console.log(localStorage)
        if (localStorage.user) {
            // remove user from storage
            localStorage.removeItem('user')
        }
        if (localStorage.admin) {
            // remove admin from storage
            localStorage.removeItem('admin')
        }

        // dispatch logout action
        dispatch({ type: 'LOGOUT' })
    }

    return { logout }
}