import { createContext, useReducer } from 'react'

export const AnnouncementContext = createContext()

export const announcementsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ANNOUNCEMENTS':
            return {
                announcements: action.payload
            }
        case 'CREATE_ANNOUNCEMENT':
            return {
                announcements: [action.payload, ...state.announcements]
            }
        case 'DELETE_ANNOUNCEMENT':
            return {
                announcements: state.announcements.filter((w) => w._id !== action.payload._id)
            }
        default:
            return state
    }
}

export const AnnouncementContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(announcementsReducer, {
        announcements: null
    })

    return (
        <AnnouncementContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AnnouncementContext.Provider>
    )
}