import $ from 'jquery';

const Parking = () => {
    $(function () {
        $("a").removeClass("current");
        $('a[href="' + document.location.pathname + '"]').addClass("current");
    });
    return (
        <div className='page'>
            <div className='page-header'>
                <img src={require('../assets/parking-icon-black.png')} alt='' />
                <h1>Parking</h1>
            </div>
            <div className="page-body">
                <iframe src='https://pmsiparking.com/parking_lots/book_now?id=1043&lat=32.7766642&lng=-96.79698789999999&type=details' title='Parking Management Portal'></iframe>
            </div>
        </div>
    )
}

export default Parking