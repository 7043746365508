import React, { useEffect, useState } from "react";
import { useUserContext } from '../hooks/useUsers'
import { useAuthContext } from '../hooks/useAuthContext'

const AdminDetails = ({ adminUser }) => {
    // console.log(admin)
    const { dispatch } = useUserContext()
    const { admin } = useAuthContext()

    const [adminUserFName, setAdminUserFName] = useState('');
    const [adminUserLName, setAdminUserLName] = useState('');
    const [adminUserEmail, setAdminUserEmail] = useState('');
    const [editAdmin, setEditAdmin] = useState('');
    const [newAdminUserFName, setNewAdminUserFName] = useState('');
    const [newAdminUserLName, setNewAdminUserLName] = useState('');
    const [newAdminUserEmail, setNewAdminUserEmail] = useState('');

    useEffect(() => {
        setAdminUserFName(adminUser.adminUserFName)
        setAdminUserLName(adminUser.adminUserLName)
        setAdminUserEmail(adminUser.adminUserEmail)
    }, [dispatch])

    const handleClick = async () => {
        if (!admin) {
            return
        }

        const response = await fetch('https://akard-dashboard-api.onrender.com/api/adminUsers/' + adminUser._id, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${admin.token}`
            }
        })
        const json = await response.json()

        if (response.ok) {
            dispatch({ type: 'DELETE_ADMIN', payload: json })
        }
    }

    function findAdmin() {
        // console.log(adminUser._id)
        if (adminUser._id) {
            setEditAdmin(true)
            setNewAdminUserFName(adminUser.adminUserFName)
            setNewAdminUserLName(adminUser.adminUserLName)
            setNewAdminUserEmail(adminUser.adminUserEmail)
        }
    }
    const handleUpdate = async (e) => {
        e.preventDefault();
        setEditAdmin(false);
        let adminUserFName = newAdminUserFName;
        let adminUserLName = newAdminUserLName;
        let adminUserEmail = newAdminUserEmail;

        const admin = { adminUserFName, adminUserLName, adminUserEmail };
        // console.log(admin)
        const response = await fetch('https://akard-dashboard-api.onrender.com/api/adminUsers/update/' + adminUser._id, {
            method: 'PUT',
            body: JSON.stringify(admin),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const json = await response.json()

        if (!response.ok) {
            console.log(json.error)
        }
        if (response.ok) {
            setEditAdmin(false);
            setAdminUserFName(newAdminUserFName)
            setAdminUserLName(newAdminUserLName)
            setAdminUserEmail(newAdminUserEmail)
            dispatch({ type: 'UPDATE_ADMIN', payload: json })
        }
    }

    // console.log(adminUser)
    return (
        <div className="user-details">
            {!editAdmin ?
                <div className="userInfo">
                    <h4>{adminUserFName} {adminUserLName}</h4>
                    <div className="mobile-spacer" />
                    <a className='userEmail' href={`mailto:${adminUserEmail}`}>{adminUserEmail}</a>
                    <div></div>
                    <div className="editDelete">
                        <span class="material-symbols-outlined delete" onClick={findAdmin}>
                            edit
                        </span>
                        <span className="material-symbols-outlined " onClick={handleClick}>delete</span>
                    </div>
                </div>
                :
                <form className="userInfo" onSubmit={handleUpdate}>
                    <input
                        type='text'
                        placeholder={adminUserFName}
                        onChange={(e) => setNewAdminUserFName(e.target.value)}
                        value={newAdminUserFName}
                    />
                    <input
                        type='text'
                        placeholder={adminUserLName}
                        onChange={(e) => setNewAdminUserLName(e.target.value)}
                        value={newAdminUserLName}
                    />
                    <input
                        type='email'
                        placeholder={adminUserEmail}
                        onChange={(e) => setNewAdminUserEmail(e.target.value)}
                        value={newAdminUserEmail}
                    />
                    <button>Submit Changes</button>
                    <span class="material-symbols-outlined closePopup" onClick={() => setEditAdmin(false)}>
                        cancel
                    </span>
                </form>}
        </div>
    )
}

export default AdminDetails