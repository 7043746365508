import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import $ from 'jquery';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from "../hooks/useAuthContext";


const Navbar = () => {
  const { logout } = useLogout();
  const { admin, show } = useAuthContext();
  const [hamburgerStatus, setHamburgerStatus] = useState('ham-closed');

  const toggleHamburger = () => {
    if (hamburgerStatus === 'ham-closed') {
      setHamburgerStatus('ham-open')
    } else {
      setHamburgerStatus('ham-closed')
    }
  }

  const handleClick = () => {
    logout()
    setHamburgerStatus('ham-closed')
  }

  $(function () {
    $('a[href="' + document.location.pathname + '"]').addClass("current");
  });
  return (
    <header>
      <div className="navbar">
        <div className="nav-top-container">
          <div className="nav-top-content">
            {show ? (<div>{admin ? (
              <Link to='/admin' className="logo-wrapper">
                <img className="nav-logo" src={require('../assets/white-akard-building-logo.png')} alt="" />
                <h1 className="logo-text">1900 North Akard</h1>
              </Link>
            ) : (<Link to='/dashboard' className="logo-wrapper">
              <img className="nav-logo" src={require('../assets/white-akard-building-logo.png')} alt="" />
              <h1 className="logo-text">1900 North Akard</h1>
            </Link>)}</div>) : (
              <Link to='/' className="logo-wrapper">
                <img className="nav-logo" src={require('../assets/white-akard-building-logo.png')} alt="" />
                <h1 className="logo-text">1900 North Akard</h1>
              </Link>
            )}

            {show && (
              <div className="nav-buttons">
                <a href='https://clientcentral.yardi.com/' target='_blank' rel='noreferrer'><button className="pay-btn">Payment</button></a>
                <Link to='/'> <button onClick={handleClick}>Logout</button> </Link>
              </div>
            )}
            {/* <span class="material-symbols-outlined hamburger-menu-icon" onClick={toggleHamburger}>
              menu
            </span> */}
            {show && (
              <span class="material-symbols-outlined hamburger-menu-icon" onClick={toggleHamburger}>
                menu
              </span>
            )}
            <div className={hamburgerStatus}>
              <div >
                {admin ?
                  <div>
                    <Link className='nav-link nav-block' to='/admin' onClick={() => setHamburgerStatus('ham-closed')}>
                      <span class=" nav-icon material-symbols-outlined ">space_dashboard</span>
                      <p className="link-label">Dashboard</p>
                    </Link>
                    <Link className='nav-link nav-block' to='/users' onClick={() => setHamburgerStatus('ham-closed')}>
                      <span class="nav-icon material-symbols-outlined">group</span>
                      <p className="link-label">Users</p>
                    </Link>
                    <Link className='nav-link nav-block' to='/organizations' onClick={() => setHamburgerStatus('ham-closed')}>
                      <span class="nav-icon material-symbols-outlined">apartment</span>
                      <p className="link-label">Organizations</p>
                    </Link>
                    <Link className='nav-link nav-block' to='/building-announcements' onClick={() => setHamburgerStatus('ham-closed')}>
                      <span class="nav-icon material-symbols-outlined">campaign</span>
                      <p className="link-label">Building Announcements</p>
                    </Link>
                    <Link className='nav-link nav-block' to='/admin-signup' onClick={() => setHamburgerStatus('ham-closed')}>
                      <span class="nav-icon material-symbols-outlined">calendar_month</span>
                      <p className="link-label">Admins</p>
                    </Link>
                  </div> : <div>
                    <Link className='nav-link nav-block' to='/visitor' onClick={() => setHamburgerStatus('ham-closed')}>
                      <img className="nav-icon" src={require('../assets/visitors-icon.png')} alt='' />
                      <p className="link-label">Visitors</p>
                    </Link>
                    <Link className='nav-link nav-block' to='/parking' onClick={() => setHamburgerStatus('ham-closed')}>
                      <img className="nav-icon" src={require('../assets/parking-icon.png')} alt='' />
                      <p className="link-label">Parking</p>
                    </Link>
                    <Link className='nav-link nav-block' to='/maintenance' onClick={() => setHamburgerStatus('ham-closed')}>
                      <img className="nav-icon" src={require('../assets/maintenance-icon.png')} alt='' />
                      <p className="link-label">Maintenance</p>
                    </Link>
                  </div>}
                <div className="mobile-nav-buttons">
                  <a href='https://clientcentral.yardi.com/' target='_blank' rel='noreferrer'><button className="pay-btn">Payment</button></a>
                  <Link to='/'> <button onClick={handleClick}>Logout</button> </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-left-container">
          <div className="side-curve">
            <div className="sidebar-curve"></div>
            <div className="sidebar-curve-white"></div>
          </div>
          <nav className="sidebar-content">
            {show && (
              <div className="menu-item">
                {admin ? <Link className='nav-link nav-block' to='/admin'>
                  {/* <img className="nav-icon" src={require('../assets/conference-room-logo_1.png')} alt='' /> */}
                  <span class=" nav-icon material-symbols-outlined tenant-dashboard">space_dashboard</span>
                  <p className="link-label">Dashboard</p>
                </Link> : null}
                <Link className='nav-link nav-block' to='/visitor'>
                  <img className="nav-icon" src={require('../assets/visitors-icon.png')} alt='' />
                  <p className="link-label">Visitors</p>
                </Link>
                {/* <Link className='nav-link nav-block' to='/conference-room'>
                  <img className="nav-icon" src={require('../assets/conference-room-logo_1.png')} alt='' />
                  <p className="link-label">Conference Room</p>
                </Link> */}
                <Link className='nav-link nav-block' to='/parking'>
                  <img className="nav-icon" src={require('../assets/parking-icon.png')} alt='' />
                  <p className="link-label">Parking</p>
                </Link>
                <Link className='nav-link nav-block' to='/maintenance'>
                  <img className="nav-icon" src={require('../assets/maintenance-icon.png')} alt='' />
                  <p className="link-label">Maintenance</p>
                </Link>
              </div>
            )}
            {!show && (<div className="menu-item"></div>)}
            <div className="footer-info">
              <h3>Managed and Leased by OliveMill Holdings</h3>
              <h4>Contacts:</h4>
              <div>
                <h5>Tenant Services:</h5>
                <a className="telephone-number" href="tel:2149788986">214.978.8986</a>
              </div>
              <div>
                <h5>Building Security:</h5>
                <a className="telephone-number" href="tel:2149788880">214.978.8880</a>
              </div>
              <a href="https://www.olivemillholdings.com/" target='_blank' rel="noreferrer"><img src={require('../assets/olive-apple.png')} alt='' className="footer-logo" /></a>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
