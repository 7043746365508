import $ from 'jquery';
const Maintenance = () => {
    $(function () {
        $("a").removeClass("current");
        $('a[href="' + document.location.pathname + '"]').addClass("current");
    });
    return (
        <div className='page'>
            <div className='page-header'>
                <img src={require('../assets/maintenance-icon-black.png')} alt='' />
                <h1>Maintenance Request</h1>
            </div>
            <div className="page-body">
                <iframe src="https://www.impaksolutions.com/olivemill/" title='IMPAK Solutions'></iframe>
            </div>
        </div>
    )
}

export default Maintenance