import $ from 'jquery';
import { useState } from 'react';

const Forgotten = () => {
    const [status, setStatus] = useState("Send Request");
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        console.log(email)

        let response = await fetch("https://akard-dashboard-api.onrender.com/api/email/forgotPassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({ email, 'role': 'client' }),
        });

        const json = await response.json()

        if (!response.ok) {
            setError(json.error)
            setStatus("Send Request");
        }
        if (response.ok) {
            setEmail('');
            setError(null);
            setStatus("Send Request");
        }
    };

    return (
        <div className='page'>
            <div className='page-header'><h2>Forgot your password?</h2></div>
            <div className="page-body">
                <form className='forgot-password-form' onSubmit={handleSubmit}>
                    <label>Email address connected to user account</label>
                    <input
                        label='Email address connected to user account'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    <button>{status}</button>
                    {error ? <p>{error}</p> : null}
                </form>
            </div>
        </div>
    )
}

export default Forgotten