import { useState, useEffect } from "react";
import $ from 'jquery';

import AdminDetails from '../components/AdminDetails';

import { useSignup } from "../hooks/useSignup";
import { useAuthContext } from "../hooks/useAuthContext";
import { useUserContext } from "../hooks/useUsers";

const AdminSignup = () => {
    $(function () {
        $("a").removeClass("current");
    });

    const { admins, dispatch } = useUserContext();
    const { admin } = useAuthContext();

    const [adminUserFName, setAdminUserFName] = useState('')
    const [adminUserLName, setAdminUserLName] = useState('')
    const [adminUserEmail, setEmail] = useState('')
    const [adminUserPassword, setPassword] = useState('')
    const { adminsignup, error, isLoading } = useSignup()

    const fetchAdmins = async () => {
        const response = await fetch('https://akard-dashboard-api.onrender.com/api/adminUsers', {
            headers: { 'Authorization': `Bearer ${admin.token}` },
        })
        const json = await response.json()

        if (response.ok) {
            setAdminUserFName('');
            setAdminUserLName('');
            setEmail('');
            setPassword('')
            dispatch({ type: 'SET_ADMINS', payload: json })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        await adminsignup(
            adminUserFName,
            adminUserLName,
            adminUserPassword,
            adminUserEmail)

        fetchAdmins()
    }

    useEffect(() => {

        if (admin) {
            fetchAdmins()
            // console.log(admins)
        }
    }, [dispatch, admin])

    return (
        <div className='page'>
            <h1 className='dashboard-header'>Admin Users</h1>
            <div className="page-body can-scroll">
                <form className="signup" onSubmit={handleSubmit}>
                    <h3>Sign up</h3>
                    <div className="multi-label-group">
                        <div className="single-label-group">
                            <label>First Name:</label>
                            <input
                                type='text'
                                onChange={(e) => setAdminUserFName(e.target.value)}
                                value={adminUserFName}
                            />
                        </div>
                        <div className="single-label-group">
                            <label>Last Name:</label>
                            <input
                                type='text'
                                onChange={(e) => setAdminUserLName(e.target.value)}
                                value={adminUserLName}
                            />
                        </div>
                    </div>
                    <div className="single-label-group">
                        <label>Email:</label>
                        <input
                            type='email'
                            onChange={(e) => setEmail(e.target.value)}
                            value={adminUserEmail}
                        />
                    </div>
                    <div className="single-label-group">
                        <label>Password:</label>
                        <input
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}
                            value={adminUserPassword}
                        />
                    </div>
                    <button disabled={isLoading}>Submit</button>
                    {error && <div className="error">{error}</div>}
                </form>
                <div className="org-wrapper">
                    {admins && admins.map((admin) => (
                        <AdminDetails key={admin._id} adminUser={admin} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AdminSignup