import React, { useEffect } from "react";
import $ from 'jquery';

// hooks
import { useUserContext } from "../hooks/useUsers";
import { useAuthContext } from "../hooks/useAuthContext";

// components
import UserDetails from '../components/UserDetails'
import Signup from '../components/Signup'

const Admin = () => {
    $(function () {
        $("a").removeClass("current");
    });
    const { users, dispatch } = useUserContext();
    const { admin } = useAuthContext();

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await fetch('https://akard-dashboard-api.onrender.com/api/orgUsers', {
                headers: { 'Authorization': `Bearer ${admin.token}` },
            })
            const json = await response.json()

            if (response.ok) {
                dispatch({ type: 'SET_USERS', payload: json })
            }
        }

        if (admin) {
            fetchUsers()
            // console.log(users)
        }
    }, [dispatch, admin])

    return (
        <div className='page'>
            <h1 className='dashboard-header'>Registered Users</h1>
            <div className="page-body can-scroll">
                <Signup />
                <div className="org-wrapper">
                    {users && users.map((user) => (
                        <UserDetails key={user._id} user={user} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Admin;
