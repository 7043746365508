import { useState } from "react";
import { useOrgContext } from "../hooks/useOrgContext";
import { useAuthContext } from '../hooks/useAuthContext';

const OrgForm = () => {
    const { dispatch } = useOrgContext()
    const { admin } = useAuthContext()

    const [orgName, setOrgName] = useState('')
    const [orgAddress1, setOrgAddress1] = useState('')
    const [orgAddress2, setOrgAddress2] = useState('')
    const [orgCity, setOrgCity] = useState('')
    const [orgState, setOrgState] = useState('')
    const [orgZip, setOrgZip] = useState('')
    const [orgPhone, setOrgPhone] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!admin) {
            setError('You must be logged in')
            return
        }

        const org = { orgName, orgAddress1, orgAddress2, orgCity, orgState, orgZip, orgPhone }

        const response = await fetch('https://akard-dashboard-api.onrender.com/api/organizations', {
            method: 'POST',
            body: JSON.stringify(org),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${admin.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            // console.log('error hit')
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if (response.ok) {
            // console.log(emptyFields)
            setOrgName('')
            setOrgAddress1('')
            setOrgAddress2('')
            setOrgCity('')
            setOrgState('')
            setOrgZip('')
            setOrgPhone('')
            setError(null)
            setEmptyFields([])
            dispatch({ type: 'CREATE_ORG', payload: json })
        }
    }

    return (
        <form className="create registration-form" onSubmit={handleSubmit}>
            <h2>Register a New Org</h2>
            <div className='multi-label-group'>
                <div className='single-label-group half-half'>
                    <label>Organization's Name:</label>
                    <input
                        type="text"
                        onChange={(e) => setOrgName(e.target.value)}
                        value={orgName}
                    // className={emptyFields.includes('orgName') ? 'error' : ''}
                    />
                </div>
                <div className='single-label-group half-half'>
                    <label>Phone:</label>
                    <input
                        type="text"
                        onChange={(e) => setOrgPhone(e.target.value)}
                        value={orgPhone}
                    // className={emptyFields.includes('orgPhone') ? 'error' : ''}
                    />
                </div>
            </div>
            <div className='multi-label-group'>
                <div className='single-label-group half-half'>
                    <label>Address Line 1:</label>
                    <input
                        type="text"
                        onChange={(e) => setOrgAddress1(e.target.value)}
                        value={orgAddress1}
                    // className={emptyFields.includes('orgAddress1') ? 'error' : ''}
                    />
                </div>
                <div className='single-label-group half-half'>
                    <label>Address Line 2:</label>
                    <input
                        type="text"
                        onChange={(e) => setOrgAddress2(e.target.value)}
                        value={orgAddress2}
                    />
                </div>
            </div>
            <div className='multi-label-group'>
                <div className='single-label-group'>
                    <label>City:</label>
                    <input
                        type="text"
                        onChange={(e) => setOrgCity(e.target.value)}
                        value={orgCity}
                    // className={emptyFields.includes('orgCity') ? 'error' : ''}
                    />
                </div>
                <div className='single-label-group'>
                    <label>State:</label>
                    <input
                        type="text"
                        onChange={(e) => setOrgState(e.target.value)}
                        value={orgState}
                    // className={emptyFields.includes('orgState') ? 'error' : ''}
                    />
                </div>
                <div className='single-label-group'>
                    <label>Zip:</label>
                    <input
                        type="number"
                        onChange={(e) => setOrgZip(e.target.value)}
                        value={orgZip}
                    // className={emptyFields.includes('orgZip') ? 'error' : ''}
                    />
                </div>
            </div>

            <button>Add Organization</button>
            {error && <div className="error">{error}</div>}
        </form>
    )
}

export default OrgForm