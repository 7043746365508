import { useState } from 'react';
import { useAnnouncementContext } from '../hooks/useAnnouncementContext';


const AnnouncementForm = () => {
    const { dispatch } = useAnnouncementContext();
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [error, setError] = useState(null);
    const [emptyFields, setEmptyFields] = useState([]);

    const handleSubmit = async (e) => {
        // e.preventDefault();
        const announcement = { title, body };
        const response = await fetch('https://akard-dashboard-api.onrender.com/api/announcements', {
            method: 'POST',
            body: JSON.stringify(announcement),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if (response.ok) {
            setTitle('');
            setBody('');
            setError(null);
            setEmptyFields([]);
            // console.log('new announcement added', json)
            dispatch({ type: 'CREATE_ANNOUNCEMENT', payload: json })
        }
    }

    return (
        <form className='announcement-form' onSubmit={handleSubmit}>
            <h3>Add a New Announcement</h3>
            <div className='single-label-group'>
                <label>Title:</label>
                <input
                    type='text'
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    className={emptyFields.includes('title') ? 'error' : ''}
                />
            </div>
            <div className='single-label-group'>
                <label>Body:</label>
                <textarea
                    type='text'
                    onChange={(e) => setBody(e.target.value)}
                    value={body}
                    className={emptyFields.includes('body') ? 'error' : ''}
                    rows="4" cols="50"
                />
            </div>
            <button>Add Announcement</button>
            {error && <div className='error'>{error}</div>}
        </form>
    )
}

export default AnnouncementForm