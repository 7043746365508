import $ from 'jquery';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router";

const Reset = () => {
    const [orgUserEmail, setOrgUserEmail] = useState('');
    const [orgUserPassword, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState(null)
    const [admin, setAdmin] = useState(false)
    const [activeReset, setActiveReset] = useState(false)
    const [orgUserFName, setOrgUserFName] = useState('')
    const location = useLocation();
    const path = location.pathname.split("/")[2];

    useEffect(() => {
        const fetchKey = async () => {
            //   console.log(path);
            let response = await fetch("https://akard-dashboard-api.onrender.com/api/orgUsers/reset/" + path, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            //   console.log(res.data);
            if (response.ok) {
                setActiveReset(true)
            }
            let adminResponse = await fetch("https://akard-dashboard-api.onrender.com/api/adminUsers/reset/" + path, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            //   console.log(res.data);
            if (adminResponse.ok) {
                setActiveReset(true)
                setAdmin(true)
            }
        };
        fetchKey();
    }, [path]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (orgUserPassword === passwordConfirm) {
            setError(null)
            if (!admin) {
                const userInfo = { orgUserEmail, orgUserPassword };

                const response = await fetch('https://akard-dashboard-api.onrender.com/api/orgUsers/reset', {
                    method: 'PUT',
                    body: JSON.stringify(userInfo),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                const json = await response.json()

                if (!response.ok) {
                    console.log(json.error)
                    setError(json.error)
                }
                if (response.ok) {
                    setError(null)
                    setOrgUserEmail('')
                    setPassword('')
                    setPasswordConfirm('')
                    setActiveReset(false)
                }
            } else if (admin) {
                const adminInfo = { 'adminUserEmail': orgUserEmail, 'adminUserPassword': orgUserPassword };
                console.log(adminInfo)

                const response = await fetch('https://akard-dashboard-api.onrender.com/api/adminUsers/reset', {
                    method: 'PUT',
                    body: JSON.stringify(adminInfo),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                const json = await response.json()

                if (!response.ok) {
                    console.log(json.error)
                    setError(json.error)
                }
                if (response.ok) {
                    setError(null)
                    setOrgUserEmail('')
                    setPassword('')
                    setPasswordConfirm('')
                    setActiveReset(false)
                }
            }
        } else {
            setError('Passwords do not match')
        }
    }

    return (
        <div className='page'>
            <div className='page-header'><h2>Reset your password</h2></div>
            <div className="page-body">
                {activeReset ?
                    <form onSubmit={handleUpdate}>
                        <div className="single-label-group">
                            <label>Email:</label>
                            <input
                                type='email'
                                onChange={(e) => setOrgUserEmail(e.target.value)}
                                value={orgUserEmail}
                            />
                        </div>
                        <div className="single-label-group">
                            <label>New Password:</label>
                            <input
                                type='password'
                                onChange={(e) => setPassword(e.target.value)}
                                value={orgUserPassword}
                            />
                        </div>
                        <div className="single-label-group">
                            <label>Confirm New Password:</label>
                            <input
                                type='password'
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                                value={passwordConfirm}
                            />
                        </div>

                        <button>Submit</button>
                        {error && <div className="error">{error}</div>}
                    </form>
                    :
                    <div className='orgAddressGroup'>
                        <Link to='/forgot-password'>Request new Password Reset Key</Link>
                        <Link to='/'>Login</Link>
                    </div>}
            </div>
        </div>
    )
}

export default Reset