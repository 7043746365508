import React, { useEffect, useState } from "react";
import { useOrgContext } from '../hooks/useOrgContext'
import { useAuthContext } from '../hooks/useAuthContext'

const OrgDetails = ({ orgs }) => {
    // console.log(orgs)
    const { dispatch } = useOrgContext()
    const { admin } = useAuthContext()

    const [orgName, setOrgName] = useState('');
    const [orgAddress1, setOrgAddress1] = useState('');
    const [orgAddress2, setOrgAddress2] = useState('');
    const [orgCity, setOrgCity] = useState('');
    const [orgState, setOrgState] = useState('');
    const [orgZip, setOrgZip] = useState('');
    const [orgPhone, setOrgPhone] = useState('');
    const [editOrg, setEditOrg] = useState('');
    const [newOrgName, setNewOrgName] = useState('');
    const [newOrgAddress1, setNewOrgAddress1] = useState('');
    const [newOrgAddress2, setNewOrgAddress2] = useState('');
    const [newOrgCity, setNewOrgCity] = useState('');
    const [newOrgState, setNewOrgState] = useState('');
    const [newOrgZip, setNewOrgZip] = useState('');
    const [newOrgPhone, setNewOrgPhone] = useState('');

    const handleClick = async () => {
        if (!admin) {
            return
        }

        const response = await fetch('https://akard-dashboard-api.onrender.com/api/organizations/' + orgs._id, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${admin.token}`
            }
        })
        const json = await response.json()

        if (response.ok) {
            dispatch({ type: 'DELETE_ORG', payload: json })
        }
    }

    useEffect(() => {
        setOrgName(orgs.orgName)
        setOrgAddress1(orgs.orgAddress1)
        setOrgAddress2(orgs.orgAddress2)
        setOrgCity(orgs.orgCity)
        setOrgState(orgs.orgState)
        setOrgZip(orgs.orgZip)
        setOrgPhone(orgs.orgPhone)
    }, [dispatch])

    function findOrg() {
        // console.log(orgs._id)
        if (orgs._id) {
            setEditOrg(true)
            setNewOrgName(orgs.orgName)
            setNewOrgAddress1(orgs.orgAddress1)
            setNewOrgAddress2(orgs.orgAddress2)
            setNewOrgCity(orgs.orgCity)
            setNewOrgState(orgs.orgState)
            setNewOrgZip(orgs.orgZip)
            setNewOrgPhone(orgs.orgPhone)
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        setEditOrg(false);
        let orgName = newOrgName;
        let orgAddress1 = newOrgAddress1;
        let orgAddress2 = newOrgAddress2;
        let orgCity = newOrgCity;
        let orgState = newOrgState;
        let orgZip = newOrgZip;
        let orgPhone = newOrgPhone;

        const orgsInfo = { orgName, orgAddress1, orgAddress2, orgCity, orgState, orgZip, orgPhone };
        // console.log(orgsInfo)
        const response = await fetch('https://akard-test-api.onrender.com/api/organizations/' + orgs._id, {
            method: 'PUT',
            body: JSON.stringify(orgsInfo),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const json = await response.json()

        if (!response.ok) {
            console.log(json.error)
        }
        if (response.ok) {
            setEditOrg(false);
            setOrgName(newOrgName)
            setOrgAddress1(newOrgAddress1)
            setOrgAddress2(newOrgAddress2)
            setOrgCity(newOrgCity)
            setOrgState(newOrgState)
            setOrgZip(newOrgZip)
            setOrgPhone(newOrgPhone)
            dispatch({ type: 'UPDATE_ORG', payload: json })
            // console.log(orgName, orgAddress1, orgAddress2, orgCity, orgState, orgZip, orgPhone)
        }
    }
    // const { orgName, orgAddress1, orgAddress2, orgCity, orgState, orgZip, orgPhone } = orgs;
    return (
        <div className="org-details">
            {!editOrg ?
                <div className='orgInfo'>
                    <h4>{orgName}</h4>
                    <div className='address-block'>
                        <p>{orgAddress1} {orgAddress2}</p>
                        <p>{orgCity}, {orgState} {orgZip}</p>
                    </div>
                    <p>{orgPhone}</p>
                    <div className="editDelete">
                        <span class="material-symbols-outlined delete" onClick={findOrg}>
                            edit
                        </span>
                        <span className="material-symbols-outlined " onClick={handleClick}>delete</span>
                    </div>
                </div>
                :
                <form className='orgInfo' onSubmit={handleUpdate}>
                    <input
                        type="text"
                        placeholder={orgName}
                        onChange={(e) => setNewOrgName(e.target.value)}
                        value={newOrgName}
                    />
                    <div className="orgAddressGroup">
                        <div className="orgAddressLine">
                            <input
                                type="text"
                                onChange={(e) => setNewOrgAddress1(e.target.value)}
                                placeholder={orgAddress1}
                                value={newOrgAddress1}
                            />
                            <input
                                type="text"
                                onChange={(e) => setNewOrgAddress2(e.target.value)}
                                placeholder={orgAddress2}
                                value={newOrgAddress2}
                            />
                        </div>
                        <div className="orgAddressLine">
                            <input
                                type="text"
                                onChange={(e) => setNewOrgCity(e.target.value)}
                                placeholder={orgCity}
                                value={newOrgCity}
                            />
                            <input
                                type="text"
                                onChange={(e) => setNewOrgState(e.target.value)}
                                placeholder={orgState}
                                value={newOrgState}
                            />
                            <input
                                type="number"
                                onChange={(e) => setNewOrgZip(e.target.value)}
                                placeholder={orgZip}
                                value={newOrgZip}
                            />
                        </div>
                    </div>
                    <input
                        type="text"
                        onChange={(e) => setNewOrgPhone(e.target.value)}
                        placeholder={orgPhone}
                        value={newOrgPhone}
                    />
                    <button>Submit Changes</button>
                    <span class="material-symbols-outlined closePopup" onClick={() => setEditOrg(false)}>
                        cancel
                    </span>
                </form>}
        </div>
    )
}

export default OrgDetails