import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ElfsightWidget } from 'react-elfsight-widget';

import AnnouncementDetails from "./AnnouncementDetails";

// hooks
import { useAuthContext } from "../hooks/useAuthContext";
import { useAnnouncementContext } from "../hooks/useAnnouncementContext";

const MobileDashboard = () => {
    const { user, admin, show } = useAuthContext();
    const { announcements, dispatch } = useAnnouncementContext();

    if (show) {
        var greeting = '';
        if (user) {
            greeting = user.orgUserFName;
        } else if (admin) {
            greeting = admin.adminUserFName
        }
    }

    useEffect(() => {
        const fetchAnnouncements = async () => {
            const response = await fetch('https://akard-dashboard-api.onrender.com/api/announcements')
            const json = await response.json()

            if (response.ok) {
                dispatch({ type: 'SET_ANNOUNCEMENTS', payload: json })
            }
        }

        fetchAnnouncements()
    }, [dispatch])
    // console.log(announcements)
    return (
        <div className="mobile-dashboard">
            {show ? <h2 className="top-header">Welcome, {greeting}</h2> : <h2 className="top-header">Dallas Weather</h2>
            }
            <div className="weather-widget">
                <ElfsightWidget widgetID="929a519e-85e9-47f0-bd6a-e014b3893daa" />
            </div>
            {!admin ? <Link className='admin-button' to='/admin-login'>Admin Login</Link> : null}
            <h2 className="right-sidebar-header">Building Announcements</h2>
            <div className="announcement-wrapper can-scroll">
                {announcements && announcements.map((announcement) => (
                    <AnnouncementDetails ket={announcement._id} announcement={announcement} />
                ))}
            </div>
        </div>
    );
};

export default MobileDashboard;
