import { useState } from "react"
import { useUserContext } from "../hooks/useUsers";
import { useAuthContext } from '../hooks/useAuthContext';
// import { useSignup } from "../hooks/useSignup"

const Signup = () => {
    const { dispatch } = useUserContext()
    const { admin } = useAuthContext()

    const [orgUserEmail, setEmail] = useState('')
    const [orgUserPassword, setPassword] = useState('')
    const [orgUserFName, setOrgfname] = useState('')
    const [orgUserLName, setOrglname] = useState('')
    const [orgName, setOrgName] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])
    // const { signup, error, isLoading } = useSignup()

    const handleSubmit = async (e) => {
        e.preventDefault()

        let orgUserName = orgUserEmail;
        // let orgUserFName = 'David';
        // let orgUserLName = 'Newman';
        // let orgName = 'FarsideDev';

        // await signup(orgUserName,
        //     orgUserFName,
        //     orgUserLName,
        //     orgUserPassword,
        //     orgName,
        //     orgUserEmail)

        const user = { orgName, orgUserName, orgUserFName, orgUserLName, orgUserPassword, orgUserEmail }

        const response = await fetch('https://akard-dashboard-api.onrender.com/api/orgUsers', {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${admin.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            // console.log('error hit')
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if (response.ok) {
            // console.log(emptyFields)
            setEmail('')
            setPassword('')
            setOrgfname('')
            setOrglname('')
            setOrgName('')
            setError(null)
            setEmptyFields([])
            dispatch({ type: 'CREATE_USER', payload: json })
        }
    }

    return (
        // <div className='page'>
        //     <h1 className='login-header'>Signup for the 1900 N. Akard Tenant Portal</h1>
        //     <div className="page-body">
        <form className="signup" onSubmit={handleSubmit}>
            <h3>Register new User</h3>
            <div className="multi-label-group">
                <div className="single-label-group">
                    <label>First Name:</label>
                    <input
                        type='text'
                        onChange={(e) => setOrgfname(e.target.value)}
                        value={orgUserFName}
                    />
                </div>
                <div className="single-label-group">
                    <label>Last Name:</label>
                    <input
                        type='text'
                        onChange={(e) => setOrglname(e.target.value)}
                        value={orgUserLName}
                    />
                </div>
                <div className="single-label-group">
                    <label>Organization Name:</label>
                    <input
                        type='text'
                        onChange={(e) => setOrgName(e.target.value)}
                        value={orgName}
                    />
                </div>
            </div>
            <div className="single-label-group">
                <label>Email:</label>
                <input
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    value={orgUserEmail}
                />
            </div>
            <div className="single-label-group">
                <label>Password:</label>
                <input
                    type='password'
                    onChange={(e) => setPassword(e.target.value)}
                    value={orgUserPassword}
                />
            </div>

            <button>Submit</button>
            {error && <div className="error">{error}</div>}
        </form>
        //     </div>
        // </div>
    )
}

export default Signup