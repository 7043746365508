import { createContext, useReducer } from 'react'

export const UserContext = createContext()

export const userReducer = (state, action) => {
    switch (action.type) {
        case 'SET_USERS':
            return {
                users: action.payload
            }
        case 'CREATE_USER':
            return {
                users: [action.payload, ...state.users]
            }
        case 'DELETE_USER':
            return {
                users: state.users.filter((w) => w._id !== action.payload._id)
            }
        case 'UPDATE_USER':
            return {
                users: action.payload
            }
        case 'SET_ADMINS':
            return {
                admins: action.payload
            }
        case 'CREATE_ADMIN':
            return {
                admins: [action.payload, ...state.admins]
            }
        case 'DELETE_ADMIN':
            return {
                admins: state.admins.filter((w) => w._id !== action.payload._id)
            }
        case 'UPDATE_ADMIN':
            return {
                admins: action.payload
            }
        default:
            return state
    }
}

export const UserContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, {
        users: null,
        admins: null
    })

    return (
        <UserContext.Provider value={{ ...state, dispatch }}>
            {children}
        </UserContext.Provider>
    )
}