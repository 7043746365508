const MobileFooter = () => {
    return (
        <div className="mobile-footer-info">
            <div>
                <h3>Managed and Leased by OliveMill Holdings</h3>
                <a href="https://www.olivemillholdings.com/" target='_blank' rel="noreferrer"><img src={require('../assets/olive-apple.png')} alt='' className="footer-logo" /></a>
            </div>
            <div>
                <h4>Contacts:</h4>
                <div>
                    <h5>Tenant Services:</h5>
                    <a className="telephone-number" href="tel:2149788986">214.978.8986</a>
                </div>
                <div>
                    <h5>Building Security:</h5>
                    <a className="telephone-number" href="tel:2149788880">214.978.8880</a>
                </div>
            </div>
        </div>
    )
}
export default MobileFooter;