import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

export const useAdminLogin = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()

    const login = async (adminUserEmail, adminUserPassword) => {
        setIsLoading(true)
        setError(null)

        const response = await fetch('https://akard-dashboard-api.onrender.com/api/adminUsers/loginadmin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ adminUserEmail, adminUserPassword })
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
            // console.log(error)
        }
        if (response.ok) {
            // save the admin to local storage
            localStorage.setItem('admin', JSON.stringify(json))
            if (localStorage.user) {
                // remove user from storage
                localStorage.removeItem('user')
            }

            // update the auth context
            dispatch({ type: 'LOGIN', payload: json })

            // update loading state
            setIsLoading(false)
        }
    }

    return { login, isLoading, error }
}