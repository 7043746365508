import $ from 'jquery';
import './login.css';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useAdminLogin } from '../hooks/useAdminLogin'

const AdminLogin = () => {
    const [adminUserEmail, setEmail] = useState('');
    const [adminUserPassword, setPassword] = useState('');
    const { login, error, isLoading } = useAdminLogin();

    $(function () {
        $("a").removeClass("current");
    });
    const handleSubmit = async (e) => {
        e.preventDefault()

        await login(adminUserEmail, adminUserPassword)
    }

    return (
        <div className='page'>
            <h1 className='login-header'>Welcome to the 1900 N. Akard Admin Portal</h1>
            <div className="page-body">
                <form className="login-form" onSubmit={handleSubmit}>
                    <img src={require('../assets/Akard_Logo_Black.png')} alt='' />
                    <div className='login-block'>
                        <label>Email</label>
                        <input
                            type='email'
                            onChange={(e) => setEmail(e.target.value)}
                            value={adminUserEmail}
                        />
                    </div>
                    <div className='login-block'>
                        <label>Password</label>
                        <input
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}
                            value={adminUserPassword}
                        />
                    </div>
                    <Link to='/forgot-admin-password' className='forgotten'> Forgot Password? </Link>
                    <button>Log In</button>
                    {error && <div className="error">{error}</div>}
                </form>
            </div>
        </div>
    )
}

export default AdminLogin