import React, { useEffect, useState } from "react";
import { useUserContext } from '../hooks/useUsers'
import { useAuthContext } from '../hooks/useAuthContext'

const UserDetails = ({ user }) => {
    // console.log(user)
    const { dispatch } = useUserContext()
    const { admin } = useAuthContext()

    const [orgName, setOrgName] = useState('');
    const [orgUserEmail, setOrgUserEmail] = useState('');
    const [orgUserFName, setOrgUserFName] = useState('');
    const [orgUserLName, setOrgUserLName] = useState('');
    const [editUser, setEditUser] = useState('');
    const [newOrgName, setNewOrgName] = useState('');
    const [newOrgUserEmail, setNewOrgUserEmail] = useState('');
    const [newOrgUserFName, setNewOrgUserFName] = useState('');
    const [newOrgUserLName, setNewOrgUserLName] = useState('');

    useEffect(() => {
        setOrgUserFName(user.orgUserFName)
        setOrgUserLName(user.orgUserLName)
        setOrgUserEmail(user.orgUserEmail)
        setOrgName(user.orgName)
    }, [dispatch])

    const handleClick = async () => {
        if (!admin) {
            return
        }

        const response = await fetch('https://akard-dashboard-api.onrender.com/api/orgUsers/' + user._id, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${admin.token}`
            }
        })
        const json = await response.json()

        if (response.ok) {
            dispatch({ type: 'DELETE_USER', payload: json })
        }
    }

    function findUser() {
        // console.log(user._id)
        if (user._id) {
            setEditUser(true)
            setNewOrgName(user.orgName)
            setNewOrgUserFName(user.orgUserFName)
            setNewOrgUserLName(user.orgUserLName)
            setNewOrgUserEmail(user.orgUserEmail)
        }
    }
    const handleUpdate = async (e) => {
        e.preventDefault();
        setEditUser(false);
        let orgName = newOrgName;
        let orgUserFName = newOrgUserFName;
        let orgUserLName = newOrgUserLName;
        let orgUserEmail = newOrgUserEmail;

        const userInfo = { orgName, orgUserFName, orgUserLName, orgUserEmail };
        // console.log(userInfo)
        const response = await fetch('https://akard-dashboard-api.onrender.com/api/orgUsers/update/' + user._id, {
            method: 'PUT',
            body: JSON.stringify(userInfo),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const json = await response.json()

        if (!response.ok) {
            console.log(json.error)
        }
        if (response.ok) {
            setEditUser(false);
            setOrgName(newOrgName)
            setOrgUserFName(newOrgUserFName)
            setOrgUserLName(newOrgUserLName)
            setOrgUserEmail(newOrgUserEmail)
            dispatch({ type: 'UPDATE_USER', payload: json })
        }
    }
    // const { orgName, orgUserEmail, orgUserFName, orgUserLName } = user.orgName;
    return (
        <div className="user-details">
            {!editUser ?
                <div className="userInfo">
                    <h4>{orgUserFName} {orgUserLName}</h4>
                    <p>{orgName}</p>
                    <p>{orgUserEmail}</p>
                    <div className="editDelete">
                        <span class="material-symbols-outlined delete" onClick={findUser}>
                            edit
                        </span>
                        <span className="material-symbols-outlined " onClick={handleClick}>delete</span>
                    </div>
                </div>
                :
                <form className="userInfo" onSubmit={handleUpdate}>
                    <input
                        type='text'
                        placeholder={orgUserFName}
                        onChange={(e) => setNewOrgUserFName(e.target.value)}
                        value={newOrgUserFName}
                    />
                    <input
                        type='text'
                        placeholder={orgName}
                        onChange={(e) => setNewOrgName(e.target.value)}
                        value={newOrgName}
                    />
                    <input
                        type='text'
                        placeholder={orgUserLName}
                        onChange={(e) => setNewOrgUserLName(e.target.value)}
                        value={newOrgUserLName}
                    />
                    <input
                        type='email'
                        placeholder={orgUserEmail}
                        onChange={(e) => setNewOrgUserEmail(e.target.value)}
                        value={newOrgUserEmail}
                    />
                    <button>Submit Changes</button>
                    <span class="material-symbols-outlined closePopup" onClick={() => setEditUser(false)}>
                        cancel
                    </span>
                </form>}
        </div>
    )
}

export default UserDetails