import React, { useEffect, useState } from "react";
import { useAnnouncementContext } from '../hooks/useAnnouncementContext'
import { useAuthContext } from '../hooks/useAuthContext'
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const AnnouncementDetails = ({ announcement }) => {
    // console.log(announcement)
    const { dispatch } = useAnnouncementContext()
    const { admin } = useAuthContext()

    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    // const [createdAt, setCreatedAt] = useState({})
    const [editAnnounce, setEditAnnounce] = useState('');
    const [newTitle, setNewTitle] = useState('');
    const [newBody, setNewBody] = useState('');
    // const [newCreatedAt, setNewCreatedAt] = useState('')

    useEffect(() => {
        setTitle(announcement.title)
        setBody(announcement.body)
        // setCreatedAt(announcement.createdAt)
    }, [dispatch])

    const handleClick = async () => {
        if (!admin) {
            return
        }

        const response = await fetch('https://akard-dashboard-api.onrender.com/api/announcements/' + announcement._id, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${admin.token}`
            }
        })
        const json = await response.json()

        if (response.ok) {
            dispatch({ type: 'DELETE_ANNOUNCEMENT', payload: json })
        }
    }

    function findAnnouncement() {
        // console.log(announcement._id)
        if (announcement._id) {
            setEditAnnounce(true)
            setNewTitle(announcement.title)
            setNewBody(announcement.body)
            // setNewCreatedAt(announcement.createdAt)
        }
    }

    const handleUpdate = async (e) => {
        // e.preventDefault();
        setEditAnnounce(false);
        let title = newTitle;
        let body = newBody;

        const announceInfo = { title, body };
        // console.log(announceInfo)
        const response = await fetch('https://akard-dashboard-api.onrender.com/api/announcements/' + announcement._id, {
            method: 'PUT',
            body: JSON.stringify(announceInfo),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const json = await response.json()

        if (!response.ok) {
            console.log(json.error)
        }
        if (response.ok) {
            window.location.reload()
            setEditAnnounce(false);
            setTitle(newTitle)
            setBody(newBody)
            // console.log(formatDistanceToNow(new Date(updatedAt), { addSuffix: true }))
            dispatch({ type: 'UPDATE_ANNOUNCEMENT', payload: json })
        }
    }

    const { createdAt, updatedAt } = announcement;
    return (
        <div className="announcement-block">
            {/* <h3>{title}</h3> */}
            {!editAnnounce ?
                <div>
                    <div>
                        <p className="announcement-body">{body}</p>
                        <p className="announcement-date">Orginally Posted: {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}</p>
                        {updatedAt !== createdAt ? <p className="announcement-date">Updated: {formatDistanceToNow(new Date(updatedAt), { addSuffix: true })}</p> : null}
                    </div>
                    {admin ? <div><span class="material-symbols-outlined delete" onClick={findAnnouncement}>
                        edit
                    </span><span className="material-symbols-outlined " onClick={handleClick}>delete</span></div> : null}
                </div>
                :
                <form className='announcement-update-form' onSubmit={handleUpdate}>
                    <input
                        type='text'
                        onChange={(e) => setNewTitle(e.target.value)}
                        placeholder={title}
                        value={newTitle}
                    />
                    <textarea
                        type='text'
                        onChange={(e) => setNewBody(e.target.value)}
                        placeholder={body}
                        value={newBody}
                        rows="10" cols="5"
                    />
                    <button>Submit Changes</button>
                    <div className='announceChangeCancel' onClick={() => setEditAnnounce(false)}>
                        <p>Cancel Changes</p>
                    </div>
                </form>}
        </div>
    )
}

export default AnnouncementDetails