import React, { useEffect } from "react";
import $ from 'jquery';

// hooks
import { useAnnouncementContext } from "../hooks/useAnnouncementContext.js";
import { useAuthContext } from "../hooks/useAuthContext";

// components
import AnnouncementDetails from '../components/AnnouncementDetails'
import AnnouncementForm from '../components/AnnouncementForm'

const BuildingAnnouncement = () => {
    $(function () {
        $("a").removeClass("current");
    });
    const { announcements, dispatch } = useAnnouncementContext();
    const { admin } = useAuthContext();

    useEffect(() => {
        const fetchAnnouncements = async () => {
            const response = await fetch('https://akard-dashboard-api.onrender.com/api/announcements', {
                headers: { 'Authorization': `Bearer ${admin.token}` },
            })
            const json = await response.json()

            if (response.ok) {
                // console.log(json)
                dispatch({ type: 'SET_ANNOUNCEMENTS', payload: json })
            }
        }

        if (admin) {
            fetchAnnouncements()
        }
    }, [dispatch, admin])

    return (
        <div className='page'>
            <h1 className='dashboard-header'>Building Announcement Portal</h1>
            <div className="page-body can-scroll">
                <h2>Announcements</h2>
                <AnnouncementForm />
                <div className="admin-announce-wrapper">
                    {announcements && announcements.map((announcement) => (
                        // console.log(announcement)
                        <AnnouncementDetails key={announcement._id} announcement={announcement} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BuildingAnnouncement;
