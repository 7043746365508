import { createContext, useReducer } from 'react'

export const OrgContext = createContext()

export const orgsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ORGS':
            return {
                orgs: action.payload
            }
        case 'CREATE_ORG':
            return {
                orgs: [action.payload, ...state.orgs]
            }
        case 'DELETE_ORG':
            return {
                orgs: state.orgs.filter((w) => w._id !== action.payload._id)
            }
        case 'UPDATE_ORG':
            return {
                orgs: action.payload
            }
        default:
            return state
    }
}

export const OrgContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(orgsReducer, {
        orgs: null
    })

    return (
        <OrgContext.Provider value={{ ...state, dispatch }}>
            {children}
        </OrgContext.Provider>
    )
}