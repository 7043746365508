import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useAuthContext } from "./hooks/useAuthContext.js";

// components
import Announcements from "./components/Announcements.js";
import Navbar from "./components/Navbar.js";
import MobileFooter from './components/MobileFooter';

// pages
import Login from "./pages/Login";
// import Conference from "./pages/Conference";
import Maintenance from "./pages/Maintenance";
import Parking from "./pages/Parking";
import Visitor from "./pages/Visitor";
import Dashboard from "./pages/Dashboard.js";
import Admin from './pages/Admin'
import AdminLogin from "./pages/adminLogin.js";
import AdminSignup from "./pages/AdminSignup.js";
import Organizations from './pages/Organizations';
import Users from './pages/Users';
import BuildingAnnouncements from './pages/BuildingAnnouncements';
// import AdminCal from './pages/AdminCal';
import Forgotten from "./pages/ForgotPassword.js";
import ForgottenAdmin from "./pages/ForgotAdminPassword.js";
import Reset from "./pages/ResetPassword.js";

//App
function App() {
  let { user, admin, show } = useAuthContext();

  // console.log(user)

  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />
        <div className='pages'>
          <Routes>
            <Route path='/' element={!show ? <Login /> : <Navigate to='/dashboard' />} />
            <Route path='/dashboard' element={show ? <Dashboard /> : <Navigate to='/' />} />
            {/* <Route path='/conference-room' element={show ? <Conference /> : <Navigate to='/' />} /> */}
            <Route path='/parking' element={show ? <Parking /> : <Navigate to='/' />} />
            <Route path='/maintenance' element={show ? <Maintenance /> : <Navigate to='/' />} />
            <Route path='/visitor' element={show ? <Visitor /> : <Navigate to='/' />} />
            {/* admin only */}
            <Route path='/admin-login' element={!admin ? <AdminLogin /> : <Navigate to='/admin' />} />
            <Route path='/admin' element={admin ? <Admin /> : <Navigate to='/admin-login' />} />
            <Route path='/organizations' element={admin ? <Organizations /> : <Navigate to='/' />} />
            <Route path='/users' element={admin ? <Users /> : <Navigate to='/' />} />
            <Route path='/building-announcements' element={admin ? <BuildingAnnouncements /> : <Navigate to='/' />} />
            {/* <Route path='/calendar' element={admin ? <AdminCal /> : <Navigate to='/' />} /> */}
            <Route path='/admin-signup' element={admin ? <AdminSignup /> : <Navigate to='/' />} />
            {/* <Route path='/signup' element={!user ? <Signup /> : <Navigate to='/dashboard' />} /> */}

            <Route path='/forgot-password' element={<Forgotten />} />
            <Route path='/forgot-admin-password' element={<ForgottenAdmin />} />
            <Route path='/reset/:token' element={!show ? <Reset /> : <Navigate to='/' />} />
            <Route path='/404' element={admin ? <Navigate to='/admin' /> : <Navigate to='/dashboard' />} />
            <Route path='*' element={<Navigate to='/404' />} />
          </Routes>
        </div>
        <Announcements className='desktop-right-sidebar' />
      </BrowserRouter>
      <MobileFooter className='mobile-footer' />
      <div className="notOptimized"><p>Optimized for this screen size coming soon!</p></div>
    </div>
  );
}

export default App;
