import $ from 'jquery';
import { Link } from "react-router-dom";
import MobileDashboard from '../components/MobileDashboard';

const Dashboard = () => {
    $(function () {
        $("a").removeClass("current");
        $('a[href="' + document.location.pathname + '"]').addClass("current");
    });

    return (
        <div className='page can-scroll'>
            <h1 className='dashboard-header'>1900 North Akard Admin Portal</h1>
            <div className="page-body admin-grid">
                <Link className='admin-dashboard-button' to='/users'>
                    <span class="material-symbols-outlined">group</span>
                    Users
                </Link>
                <Link className='admin-dashboard-button' to='/organizations'>
                    <span class="material-symbols-outlined">apartment</span>
                    Organizations
                </Link>
                <Link className='admin-dashboard-button' to='/building-announcements'>
                    <span class="material-symbols-outlined">campaign</span>
                    Building Announcements
                </Link>
                {/* <Link className='admin-dashboard-button' to='/calendar'>
                    <span class="material-symbols-outlined">calendar_month</span>
                    Calendar
                </Link> */}
                <Link className='admin-dashboard-button' to='/admin-signup'>
                    <span class="material-symbols-outlined">calendar_month</span>
                    Admins
                </Link>
            </div>
            <MobileDashboard className='mobile-dashboard' />
        </div>
    )
}

export default Dashboard